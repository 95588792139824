
        <template>
          <div class="vc-snippet-doc">
            <h2>ContextMenu 右击菜单</h2>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> @<span class="hljs-attr">contextmenu.native.prevent</span>=<span class="hljs-string">"showMenu"</span>&gt;</span>右击菜单<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-context-menu</span> <span class="hljs-attr">:contextMenuData</span>=<span class="hljs-string">"contextMenuData"</span>
             <span class="hljs-attr">:transferIndex</span>=<span class="hljs-string">"transferIndex"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-context-menu</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">transferIndex</span>: <span class="hljs-literal">null</span>,
            <span class="hljs-attr">contextMenuData</span>: {
                <span class="hljs-attr">menuName</span>: <span class="hljs-string">'demo'</span>,
                <span class="hljs-attr">axios</span>: {
                    <span class="hljs-attr">x</span>: <span class="hljs-literal">null</span>,
                    <span class="hljs-attr">y</span>: <span class="hljs-literal">null</span>
                },
                <span class="hljs-attr">menulists</span>: [{
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'renameAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'edit'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'重命名'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">true</span>
                },
                {
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'moveAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'navigate'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'移动到...'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
                },
                {
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'copyAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'ios-copy'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'复制到...'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
                },
                {
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'shareAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'share'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'分享给...'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
                },
                {
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'downloadAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'ios-cloud-download'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'下载'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
                },
                {
                    <span class="hljs-attr">selectable</span>: <span class="hljs-literal">true</span>
                },
                {
                    <span class="hljs-attr">fnHandler</span>: <span class="hljs-string">'delAction'</span>,
                    <span class="hljs-attr">icoName</span>: <span class="hljs-string">'trash-b'</span>,
                    <span class="hljs-attr">btnName</span>: <span class="hljs-string">'删除'</span>,
                    <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
                }
                ]
            },
        }
    },
    <span class="hljs-attr">methods</span>: {
        showMenu($event){
            <span class="hljs-keyword">this</span>.transferIndex = <span class="hljs-number">0</span>;
            <span class="hljs-keyword">this</span>.contextMenuData.axios = {
                <span class="hljs-attr">x</span>:$event.clientX, <span class="hljs-attr">y</span>:$event.clientY
            };
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>contextMenuData</td>
<td>配置</td>
<td>Object</td>
<td>—</td>
<td></td>
</tr>
<tr>
<td>transferIndex</td>
<td>索引</td>
<td>Number</td>
<td>—</td>
<td><code>null</code></td>
</tr>
</tbody>
</table>
<h3>PS:</h3>
<p>参考自：<a href="https://github.com/chiic/vue-contextmenu/blob/master/src/VueContextMenu.vue">VueContextMenu</a></p>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-button',{nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu($event)}}},[_vm._v("右击菜单")]),_vm._v(" "),_c('ixu-context-menu',{attrs:{"contextMenuData":_vm.contextMenuData,"transferIndex":_vm.transferIndex}})],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            transferIndex: null,
            contextMenuData: {
                menuName: 'demo',
                axios: {
                    x: null,
                    y: null
                },
                menulists: [{
                    fnHandler: 'renameAction',
                    icoName: 'edit',
                    btnName: '重命名',
                    disabled: true
                },
                {
                    fnHandler: 'moveAction',
                    icoName: 'navigate',
                    btnName: '移动到...',
                    disabled: false
                },
                {
                    fnHandler: 'copyAction',
                    icoName: 'ios-copy',
                    btnName: '复制到...',
                    disabled: false
                },
                {
                    fnHandler: 'shareAction',
                    icoName: 'share',
                    btnName: '分享给...',
                    disabled: false
                },
                {
                    fnHandler: 'downloadAction',
                    icoName: 'ios-cloud-download',
                    btnName: '下载',
                    disabled: false
                },
                {
                    selectable: true
                },
                {
                    fnHandler: 'delAction',
                    icoName: 'trash-b',
                    btnName: '删除',
                    disabled: false
                }
                ]
            },
        }
    },
    methods: {
        showMenu($event){
            this.transferIndex = 0;
            this.contextMenuData.axios = {
                x:$event.clientX, y:$event.clientY
            };
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>